<template>
  <div>
    <div align="center" class="my-5" v-if="dati_vuoti == ''">
      <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      variant="primary"
      />
    </div>

    <section id="dashboard-ecommerce" class="mb-4" v-if="dati_vuoti != ''">
      <b-card border-variant="primary" bg-variant="white">  
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ data.lead.titolo }}</h2>
          <div class="text-right"><small class="text-muted">Aggiornato a</small><br />{{ data.lead.data_aggiornamento }}</div>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center">
          <h4>stato richiesta: {{ data.lead.stato_nome }}</h4>
          <div v-if="data.lead.link_ordine.id_ordine != ''">
            <b-button
            variant="primary"
            @click="$router.push({ name: 'azienda-ordine-infostronzo', params: { id: data.lead.link_ordine.id_ordine, numero_ordine: data.lead.link_ordine.numero_ordine }})"
            >
                <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-1"
                />Ripilogo Ordine
            </b-button>
          </div>
        </div>
        
        <div v-if="data.lead.note != ''">
          <hr />
          <small class="text-muted">note: </small><br />{{ data.lead.note }}
        </div>
      </b-card>

      <div
        v-for="(riga,index) in data.step"
        :key="index"
      >
        <b-card bg-variant="Default">
         <div class="d-flex justify-content-between align-items-center">
            <h3>{{ riga.titolo }}<br /><small><i>da:</i> <span class="text-primary">{{ riga.nome_utente }}</span></small></h3>
            <div class="text-right"><small class="text-muted">Aggiornato a</small><br />{{ riga.data_aggiornamento }}</div>
          </div>
          <div v-if="riga.valore != ''">
            <hr />
            <small class="text-muted">Messaggio / Note: </small><br />{{ riga.valore }}
          </div>
          <div v-if="riga.concluso == 1">
            <hr />
            <h5 class="text-success">Chiuso</h5>
          </div>
        </b-card>
      </div>

      <div class="text-center mb-4">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle.sidebar-right
          variant="outline-primary"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="mr-1"
          />Rispondi
        </b-button>
      </div>

      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
        width="500px"
        v-if="dati_vuoti != ''"
      >
        <div class="p-2">
          <h3 class="pb-2">Nuovo Messaggio per la tua Richiesta</h3>

          <validation-observer ref="simpleRules">
            <b-form>

              <b-form-group label="Tipo di Messaggio" label-for="id_tipo">
                  <validation-provider
                  #default="{ errors }"
                  name="Tipo di Messaggio"
                  rules="required"
                  >
                  <b-form-select
                      v-model="campiform.selected"
                      :options="options"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>

              <b-form-group label="Titolo della Richiesta" label-for="titolo">
                  <validation-provider
                  #default="{ errors }"
                  name="Titolo"
                  rules="required"
                  >
                  <b-form-input
                      v-model="campiform.titolo"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>

              <b-form-group label="Messaggio / Note" label-for="note">
                  <b-form-textarea
                      v-model="campiform.note"
                      placeholder="Descrivi brevemente la tua richiesta"
                      rows="5"
                  />
              </b-form-group>

              <b-button
                  variant="primary"
                  block
                  type="submit"
                  @click.prevent="validationForm(data.lead.id,userData.id_azienda)"
              >
                  Salva
              </b-button>

            </b-form>
          </validation-observer>
          
        </div>
      </b-sidebar>

    </section>

  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BButton, BCard, BCardText, BCardTitle, BAvatar, BCardBody, BMedia, BMediaAside, BMediaBody, BSpinner, 
BSidebar, VBToggle, BForm, BFormGroup, BFormTextarea, BFormSelect, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BRow,
    BCol,
    BButton,
    BCard, 
    BCardText, 
    BCardTitle,
    BAvatar,
    BCardBody, 
    BMedia, 
    BMediaAside, 
    BMediaBody, 
    BSpinner,
    BSidebar,

    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      dati_vuoti: '',
      data: {
        lead: {
          link_ordine: { }
         },
        step: { },
        lead_step_tipo: { }
      },
      userData: {},
      solidColor: [
        { bg: 'primary', title: 'Primary card title' },
        { bg: 'secondary', title: 'Secondary card title' },
        { bg: 'success', title: 'Success card title' },
        { bg: 'danger', title: 'Danger card title' },
        { bg: 'warning', title: 'Warning card title' },
        { bg: 'info', title: 'Info card title' },
      ],
      
      campiform: {
          titolo: '',
          note: '',
          selected: '1',
      },
      required,
      options: [ ],
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    console.log('id_lead (in azienda) -> '+router.currentRoute.params.id_lead);

    //this.dati_vuoti = 'no';
    
    this.caricatoTutto()
    
  },
  methods: {
    caricatoTutto(){
      this.$http.get('v2/azienda/infoleadgenerale/'+this.userData.id_azienda+'/'+router.currentRoute.params.id_lead)
      .then(response => {
        
        if((response.data.lead['id'] == router.currentRoute.params.id_lead) && (this.userData.id_azienda == response.data.lead['id_azienda'])){
          //ordine_trovato
          this.dati_vuoti = 'mo';

          //visualizza dati dell'ordine ricevuti
          this.data = response.data

          //carico i dati nel select del form "nuovo step"
          this.options = this.data.lead_step_tipo
          //seleziono nel select la prima voce restiuita
          this.campiform.selected = this.data.lead_step_tipo[0].value
        } else {
          //ordine_trovato
          this.dati_vuoti = '';

          //azzera dati ordine visualizzati
          this.data = { }
          this.options = [ ]

          //accesso ad una richiesta NON DELLA TUA AZIENDA !!!
          this.$router.replace('../leads')
          .then(() => {
            this.$swal({
                icon: 'error',
                title: 'Accesso Negato',
                text: 'hai provato ad accedere ad una richiesta non effettuata dalla tua azienda',
                confirmButtonText: 'chiudi',
                customClass: {
                confirmButton: 'btn btn-success',
                },
            })
          })
        }

        console.log(response.data);

      })
    },
    chiudiSidebar(){
      console.log("CHIUSURA sidebar ... speriamo bene ...")
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    validationForm(id_lead,id_azienda_passata) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          console.log('start invio form ... spriamo bene')
          console.log('Titolo Richiesta -> '+this.campiform.titolo)

          //aggiungi ID_azienda al post in partenza
          this.campiform = Object.assign({}, this.campiform, {
            id_lead: parseInt(id_lead), 
            id_azienda: parseInt(id_azienda_passata), 
            id_utente: parseInt(this.userData.id)}
          );

          console.log(this.campiform)
          
          this.$http.post('v2/azienda/salvanuovoleadstep', {
            dati_form: this.campiform 
            }).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if(response.data.statusCode===200){
                //risposta positiva
                this.chiudiSidebar()
                this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                this.caricatoTutto()
              } else {
                //risposta negativa (errore sul server)
                this.chiudiSidebar()
                this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
